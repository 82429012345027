import type { EnquiryMultiStepObj } from '~/models/EnquiryCapture/EnquiryMultiStepObj'
import { contentServiceURL, travelExplorerURL } from './apiDomain'
import { createHttp, travelExplorerHttp } from './http'
// import {contentServiceURL} from './apiDomain'
// import {createHttp} from './http'

// let client = travelExplorerHttp(travelExplorerURL)
// let clientService = createHttp(contentServiceURL)

export default {
    submit(payload) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.post(`/enquiry`, payload)
    },
    submitSingleEnquiry(payload) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.post(`/single-enquiry`, payload)
    },
    getOptionsEnquiry({ id, currency }) {
        const clientService = createHttp(contentServiceURL)
        return clientService.get(`/trips/${id}/${currency}/enquiry-options`)
    },
    getOptionsEnquiryNew(params) {
        const clientService = createHttp(contentServiceURL)
        return clientService.get(`/enquiries/enquiry-options`, {params})
    },
    getOptionsEnquiryDestinations(country_url) {
        const clientService = createHttp(contentServiceURL)
        return clientService.get(`/destinations/${country_url}/enquiry-options`)
    },
    getAffiliateInfo({ aff_id }) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.get(`/affiliate/${aff_id}`)
    },
    postGTMTracking(id, data) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.post(`/enquiry/${id}/update`, data)
    },
    getEnquiriesPriceRange(params: { country_code: string; month: string; currency: string; hotel_star: string }) {
        const client = travelExplorerHttp(contentServiceURL)
        return client.get(`/enquiries/price-range`, { params })
    },
    getEnquiryByID(id: string | number) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.get(`/multi-step-enquiries/${id}`)
    },
    submitMultiStepEnquiry(payload: EnquiryMultiStepObj) {
        const client = travelExplorerHttp(travelExplorerURL)
        return client.post(`/multi-step-enquiry`, payload)
    },
}
